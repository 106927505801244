import { cssHexWithAlphaToRGBA } from '@styles/utils';

const baseColors = {
    NAVY: '#15206B',
    BLUE: '#3856A7',
    BLUE_LIGHT: '#D5D9F8',
    BLUE_LIGHTER: '#D5D9E8',
    BLUE_EXTRA_LIGHT: '#EBEEF6',
    BLUE_GRAY: '#C9D8FE',

    RED_DARK: '#D0230E',
    RED: '#FC4D38',
    RED_LIGHT: '#FFEDEB',

    PURPLE: '#5D5EF8',

    ORANGE: '#FF9528',
    ORANGE_LIGHT: '#FFEAD4',

    PINK_DARK: '#F688A2',
    PINK: '#FD6351',
    PINK_LIGHT: '#FFD9E0',
    PINK_LIGHTER: '#F7D5CC',
    PINK_EXTRA_LIGHT: '#FFECF0',

    GREEN_DEEP_JUNGLE: '#004E48',
    GREEN_BRIGHT: '#00865A',
    GREEN_LIGHT: '#A8D4CD',
    MINT: '#C9E5DE',
    MINT_DARKER: '#ABD1C8',
    TEAL_EXTRA_LIGHT: '#EBF5F0',

    BEIGE_LIGHT: '#F2E5D6',
    BEIGE_MILESTONE: '#F8F5ED',
    BEIGE_OVERVIEW: '#F8F4E9',
    BEIGE_EXTRA_LIGHT: '#FFF5E8',
    BEIGE_GRAY: '#EEEDE9',

    BLACK: '#000000',
    BLACK_LIGHT: '#262626',
    GRAY: '#F2F2F2',
    WHITE: '#FFFFFF',

    GOLD: '#FAA634',
    SILVER: '#C9CED6',
    BRONZE: '#EA7559',

    TRANSPARENT: 'transparent',

    // custom colors
    BEIGE_PDP: '#F1E0D1',
    PINK_PDP: '#FDD0D3',
    // PINK_HOMEPAGE_TOP: '#FFC7D5',
    PINK_HOMEPAGE_TOP: '#ffd9e0',
    PINK_HOMEPAGE: '#FDD3DC',
    PINK_WHATS_IN_BOX: '#FFD4DE',
    PINK_ASHES: '#F0E4E8',
    PINK_CANCELLATION: '#FFDDE3',
    BLUE_EXTRA_LIGHER: '#F9FAFD',
    PICK_A_PLAN_CONTAINER: '#F7F8FB',
    SMART_BARS_CONTAINER: '#F2E5D5',
    SMOOTHIES_CONTAINER: '#f2e5d5',
    PLP_BUNDLES_CONTAINER: '#F2E5D7',
    CANCELLATION_SURVEY_DISABLED_TEXTAREA: '#757575',
    COMING_SOON: '#E9E1D6',
    PEPPA_PIG_BLUE: '#85B8F9',
    PINK_SHOP_BUTTON: '#FFB2C4',
    BLUE_HOMEPAGE_EXTRA_LIGHT: '#E2EAFF',
    ORANGE_HOMEPAGE: '#FFE4C4',
    HOMEPAGE_BUNDLE_ONE: '#008896',
    HOMEPAGE_BUNDLE_TWO: '#00865A',
    HOMEPAGE_BUNDLE_THREE: '#3856A7',
    HOMEPAGE_BUNDLE_FOUR: '#9D326C',
    PDPAGE_PRICE_GREY: '#858585',
    PDPAGE_TABS_SECTION_LIGHT_BLUE: '#DCE3FA',
    FOOTER_PINK: '#F7B0C2',
    HOMEPAGE_REVIEWS_LIGHT_BLUE: '#E3EBFF',
};

const colorsWithTransparency = {
    NAVY_15: cssHexWithAlphaToRGBA(baseColors.NAVY, 15),
    NAVY_10: cssHexWithAlphaToRGBA(baseColors.NAVY, 10),

    BLUE_60: cssHexWithAlphaToRGBA(baseColors.BLUE, 60),
    BLUE_50: cssHexWithAlphaToRGBA(baseColors.BLUE, 50),
    BLUE_20: cssHexWithAlphaToRGBA(baseColors.BLUE, 20),

    BLUE_LIGHT_40: cssHexWithAlphaToRGBA(baseColors.BLUE_LIGHT, 40),
    BLUE_LIGHTER_40: cssHexWithAlphaToRGBA(baseColors.BLUE_LIGHT, 40),

    BLACK_15: cssHexWithAlphaToRGBA(baseColors.BLACK, 15),
    BLACK_10: cssHexWithAlphaToRGBA(baseColors.BLACK, 10),
    BLACK_05: cssHexWithAlphaToRGBA(baseColors.BLACK, 5),

    BLACK_LIGHT_65: cssHexWithAlphaToRGBA(baseColors.BLACK_LIGHT, 65),
    BLACK_LIGHT_55: cssHexWithAlphaToRGBA(baseColors.BLACK_LIGHT, 55),
    BLACK_LIGHT_20: cssHexWithAlphaToRGBA(baseColors.BLACK_LIGHT, 20),
    BLACK_LIGHT_05: cssHexWithAlphaToRGBA(baseColors.BLACK_LIGHT, 5),

    WHITE_95: cssHexWithAlphaToRGBA(baseColors.WHITE, 95),
    WHITE_65: cssHexWithAlphaToRGBA(baseColors.WHITE, 65),
    WHITE_60: cssHexWithAlphaToRGBA(baseColors.WHITE, 60),
    WHITE_40: cssHexWithAlphaToRGBA(baseColors.WHITE, 40),

    GRAY_60: cssHexWithAlphaToRGBA(baseColors.GRAY, 60),
}

const colors = Object.assign({}, baseColors, colorsWithTransparency);

export default colors;
