import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { TrackJS } from 'trackjs';

import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

import App from '@v2/app';
import { store } from '@app/store';
import reportWebVitals from '@v2/reportWebVitals';

if (process.env.TRACKJS_KEY) {
    TrackJS.install({
        token: process.env.TRACKJS_KEY,
        console: {
            display: false
        }
    });
}

window.onscroll = function() {
    function isInViewport(element: any, percentile: number) {
        const rect = element.getBoundingClientRect();

        return rect.top < window.innerHeight/percentile;
    }

    const body = document.getElementsByClassName('e-page-content')[0];

    if (window.pageYOffset === 0) {
        body.classList.remove('is-scrolled', 'is-scrolled-50', 'is-scrolled-60');
    } else if (window.pageYOffset > 60) {
        body.classList.add('is-scrolled', 'is-scrolled-50', 'is-scrolled-60');
    } else if (window.pageYOffset > 50) {
        body.classList.add('is-scrolled', 'is-scrolled-50');
    } else if (window.pageYOffset > 0) {
        body.classList.add('is-scrolled');
    }

    const animatedContainers = document.getElementsByClassName('animated-container');
    Array.from(animatedContainers).forEach((animatedContainer) => {
        if (isInViewport(animatedContainer, parseFloat(animatedContainer.getAttribute('animationtrigger')))) {
            const animations = animatedContainer.getElementsByClassName('animated');

            Array.from(animations).forEach((animation) => {
                for (const className of animation.getAttribute('animations').split(' ')) {
                    animation.classList.add(className);
                }

                animation.classList.remove('invisible', 'animated');
            });
        }
    });
};

// Prevents IOS devices from zooming in
document.addEventListener('gesturestart', e => e.preventDefault());
document.addEventListener('gesturechange', e => e.preventDefault());
document.addEventListener('gestureend', e => e.preventDefault());

const persistor = persistStore(store);
const root = createRoot(document.getElementById('root'));

root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <App />
        </PersistGate>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
