import React, { useEffect, useState } from 'react';

import { BrowserRouter as Router } from 'react-router-dom';
import { store } from '@app/store';

import ErrorBoundary from '@components/ui/ErrorBoundary';
import ScrollToTop from '@components/ui/ScrollToTop';
import { identify, loadTraits, location } from '@v2/services/analytics.service';
import { usePDPVideoModal /*, usePricingChangesModal*/ } from '@components/common/presentational/modal/common';
import { CanadaPage, useInternationalModal } from '@components/pages/international/international.page';

import ModalWrapper from '@components/common/presentational/modal/modal';

import Header from '@components/common/presentational/header/header';
import Footer from '@components/common/presentational/footer/footer';

import Routes from '@v2/routes';

// Commented according to story 6387
// import GorgiasInit from '@components/common/gorgias/gorgias-init';

import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';

import appStyle from '@styles/app';
import styles from '@styles/wrapper';

import '@v1/styles/base.scss';
import { getByKey, setByKey } from './utils/localstorage';
import { useIsLogged, useJWT /*, useUser*/ } from './store/rtk.user.selectors';
import { IntercomInit } from './components/common/intercom/intercom-init';
import { useAccessibility } from './hooks/useAccessibility';

/*
  Check if we can get rid of:
    - html-react-parser
    - normalizr
    - react-bootstrap/*
    - react-scroll
    - react-slick
    - react-slider
    - react-swipeable
    - redux-immutable
    - redux-thunk
*/


// I have used this to access the store in logoutUser as a quick fix
(window as any).store = store;
(window as any).React = React;
(window as any).debugGTM = false;

function Cerebelly() {
    useAccessibility();
    const jwtToken = useJWT();
    const isLogged = useIsLogged();
    // const user = useUser();

    const gtmParams = { id: process.env.GTAG_ID };

    const [isCA, setIsCA] = useState((window as any).location.origin.includes('cerebelly.ca'));
    const [playing, setPlaying] = useState(false);
    const [showInternationalModal] = useInternationalModal();
    // const [showPricingModal] = usePricingChangesModal((user && user.isSubscribed) ? true : false);

    const [showPIAAVideoModal] = usePDPVideoModal(
        'https://content.jwplatform.com/videos/cMWN61GD-Cx61c2kK.mp4',
        'piaa'
    );

    useEffect(() => {
        if (jwtToken) {
            // @ts-ignore
            (async () => {
                const traits = await loadTraits() as any;

                if (traits && traits.id) {
                    identify(traits.id, traits);
                }
            })();

            /*
            if (user && !localStorage.getItem('cb-pricing-notified')) {
                showPricingModal();
                localStorage.setItem('cb-pricing-notified', '1');
            }
            */
        }
    }, [jwtToken]);

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);

        if (!query) {
            return;
        }

        if (query.get('video') === 'piaa' && !playing) {
            setPlaying(true);
            showPIAAVideoModal();
        } else if (query.get('modal') === 'international') {
            showInternationalModal();
        }
    }, [window.location.search]);

    useEffect(() => {
        (async () => {
            const current = await location() as any;
            const stored = getByKey('location');

            if (!current || !current.country || (stored && stored.country === current.country)) {
                return;
            }

            setByKey('location', current);

            if (current.country === 'US' && (window as any).location.origin.includes('cerebelly.ca')) {
                window.location.replace('https://www.cerebelly.com');
            } else if (current.country === 'US') {
                return;
            } else if (current.country === 'CA' || ((window as any).location.origin.includes('cerebelly.com') && current.country === 'CA')) {
                setIsCA(true);
            } else {
                showInternationalModal();
            }
        })();
    }, []);

    return (
        <div className={`e-page-content with-promo ${isLogged ? 'is-logged' : 'is-not-logged'} ${styles.v2Global} ${appStyle(null)}${isCA ? ' canada' : ''}`}>
            {isCA
                ?
                <CanadaPage />
                :
                <GTMProvider state={gtmParams}>
                    <Router>
                        <ScrollToTop>
                            <ErrorBoundary>
                                <div id="header-section">
                                    <Header />
                                </div>
                                <Routes />
                                <div id="footer-section">
                                    <Footer />
                                </div>
                                <ModalWrapper />
                                {/* // Commented according to story 6387  */}
                                {/* {isLogged &&
                                    <GorgiasInit />
                                } */}
                                <IntercomInit />
                            </ErrorBoundary>
                        </ScrollToTop>
                    </Router>
                </GTMProvider>
            }
        </div>
    );
}

function App() {
    return (
        <Cerebelly />
    );
}

export default App;