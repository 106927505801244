import { Suspense, lazy, useEffect, useState, createContext } from 'react';
import { Switch, Route, Redirect, withRouter, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import PrivateRoute from '@v2/components/ui/PrivateRoute';

import PageLoader from '@components/common/presentational/loader/page-loader';
import Logout from '@v2/components/pages/logout/logout.page';
import { Modal } from '@v2/components/common/presentational/modal/modal';

import {
    pdpAgeBundleModalSettings, pdpModalPromiseSettings,
    pdpVarietyPackModalSettings, surveyModalSettings, useBuilderModalSettings
} from '@v2/components/common/presentational/modal/common';

import { emptyCart } from '@app/store/rtk.cart.store';
import { IProduct } from "@interfaces/_cart";
import ProductService from '@v2/services/product.service';
import { page } from '@v2/services/analytics.service';
import { removeByKey } from '@utils/localstorage';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

import HomePage from '@v2/components/pages/home/home.page';
import YotpoInit from './components/common/yotpo/yotpo-init';
import { useShopModals } from './store/rtk.modals.selectors';
import { closeShopModals, popShopModal, pushShopModal, updateShopModal } from './store/rtk.modals.store';
import { IModalSettings } from './components/common/presentational/modal/interfaces';
import { store } from './store';
import NextDoorPixes from './components/common/external/nextdoor-pixel';
import StackAdaptPixes from './components/common/external/stackadapt-pixel';
import { useHistoryWithReferrer } from './utils/history';
import CRSOptimizer from './components/common/external/crs-optimizer';

const LoginModal = lazy(() => import('@v2/components/common/presentational/header/modals/login'));
const SwapInitialModal = lazy(() => import('@v2/components/pages/swap/initial'));
const SwapProductsModal = lazy(() => import('@v2/components/pages/swap/products'));
const SwapSurveyModal = lazy(() => import('@v2/components/pages/swap/survey'));
const SwapBuilderModal = lazy(() => import('@v2/components/pages/swap/builder'));
const SwapConfirmationModal = lazy(() => import('@v2/components/pages/swap/confirm'));
const SwapOOSMOdal = lazy(() => import('@v2/components/pages/swap/oos'));
const SwapPacksModal = lazy(() => import('@v2/components/pages/swap/packs'));

const EditPersonalized = lazy(() => import('@v2/components/pages/edit/personalized'));
const EditMilestones = lazy(() => import('@v2/components/pages/edit/milestones'));

const BuyAgainList = lazy(() => import('@v2/components/pages/buy-again/list'));
const BuyAgainPersonalized = lazy(() => import('@v2/components/pages/buy-again/personalized'));

const OurStoryPage = lazy(() => import('@v2/components/pages/static/OurStory'));
const PrivacyPolicy = lazy(() => import('@v2/components/pages/static/PrivacyPolicy'));
const MessagingPrivacyPolicy = lazy(() => import('@v2/components/pages/static/MessagingPrivacyPolicy'));
const ReferralTerms = lazy(() => import('@v2/components/pages/static/ReferralTerms'));
const TermsUse = lazy(() => import('@v2/components/pages/static/TermsUse'));
const TermsSale = lazy(() => import('@v2/components/pages/static/TermsSale'));
const AccessibilityStatement = lazy(() => import('@v2/components/pages/static/AccessibilityStatement'));
const Science = lazy(() => import('@v2/components/pages/static/Science'));
const NutrientDetailsPage = lazy(() => import('@v2/components/pages/static/NutrientDetails'));
const HowItWorks = lazy(() => import('@v2/components/pages/static/HowItWorks'));
const Baby2Baby = lazy(() => import('@v2/components/pages/static/Baby2Baby'));
const Recycling = lazy(() => import('@v2/components/pages/static/Recycling'));
const SampleKit = lazy(() => import('@v2/components/pages/static/SampleKit'));
const SocialProof = lazy(() => import('@v2/components/pages/static/SocialProof'));
const Listicle = lazy(() => import('@v2/components/pages/static/Listicle'));

const WhereToBuyPage = lazy(() => import('@v2/components/pages/stores/stores.page'));
const LoyaltyProgramPage = lazy(() => import('@v2/components/pages/loyalty/loyalty.page'));

const ResetPasswordPage = lazy(() => import('@v2/components/pages/reset-password/reset-password.page'));
const CartPage = lazy(() => import('@v2/components/pages/cart/cart.page'));
const GiftPage = lazy(() => import('@v2/components/pages/gift/gift.page'));
const GuestCheckoutPage = lazy(() => import('@v2/components/pages/checkout/guest/checkout.guest.page'));
const LoggedCheckoutPage = lazy(() => import('@v2/components/pages/checkout/logged/checkout.logged.page'));
const OrderConfirmationPage = lazy(() => import('@v2/components/pages/confirmation/confirmation.page'));
const GiftOrderConfirmationPage = lazy(() => import('@v2/components/pages/confirmation-gift/confirmation-gift.page'));
const PIAAPage = lazy(() => import('@v2/components/pages/blog/piaa.page'));

const ShopPage = lazy(() => import('@v2/components/pages/shop/shop.page'));

const BuilderIOLanding = lazy(() => import('@builderio/landing/landing'));
const BuilderIOReviews = lazy(() => import('@builderio/reviews/reviews'));

const ChildPage = lazy(() => import('@v2/components/pages/child/child.page'));
const DashboardLayoutPage = lazy(() => import('@v2/components/pages/dashboard-layout/dashboard-layout.page'));
const CancellationPage = lazy(() => import('@v2/components/pages/cancellation/cancellation.page'));

const NotFoundPage = lazy(() => import('@v2/components/pages/not-found/not-found.page'));

export const RouterContext = createContext({ from: '', to: '' });

const RouterProvider = ({ children }: any) => {
  const location = useLocation()
  const [route, setRoute] = useState({ to: location.pathname, from: location.pathname });

  useEffect(() => {
    setRoute((prev) => ({ to: location.pathname, from: prev.to }) )
  }, [location]);

  return <RouterContext.Provider value={route}>
    {children}
  </RouterContext.Provider>
}


function Routes() {
    const dispatch = useDispatch();
    const shopModals = useShopModals();
    const gtmDispatch = useGTMDispatch();
    const history = useHistoryWithReferrer();
    const location = history.location;
    const pathname = (location && location.pathname) ? location.pathname : null;
    const builderModalSettings = useBuilderModalSettings();

    const hideShopModal = () => {
        const query = new URLSearchParams(window.location.search);

        if (shopModals.length !== 1 || query.get('back')) {
            history.goBack();
            if(!pathname.startsWith('/box-builder')) {
                dispatch(closeShopModals());
            }
            return;
        }

        if (pathname.startsWith('/buy-again')) {
            // history.goBack();
        } else if (pathname.startsWith('/swap')) {
            history.pushWithReferrer('/subscription');
            dispatch(emptyCart());
            removeByKey('cart-form');
        } else if (
            pathname.startsWith('/bundle/age/') ||
            pathname.startsWith('/variety/age/') ||
            pathname.startsWith('/product/')
        ) {
            /* Story-5224: navigation bugfix, uncomment code to revert prev behavoir */
            history.goBack();
        } else {
            history.goBack();
        }
    }

    function navigate(location: any, initialAction: string) {
        let action = initialAction;
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        const locationsStack = (window as any).shopLocations;
        const found = shopModals.reduce((found, settings) => found || settings.path === location.pathname, false);
        const query = new URLSearchParams(location.search);

        if (!found && initialAction === 'POP' && locationsStack[locationsStack.length - 2] === location.key) {
            action = 'PUSH';
        }

        // Close shop modals when return to the home screen for not logged in users
        if(location.pathname === '/') {
            dispatch(closeShopModals());
        }

        // Reset title to Cerebelly after navigate away from page with other title
        document.title = 'Cerebelly';

        switch (action.toUpperCase()) {
            case 'POP': {
                dispatch(popShopModal());
                break;
            }
            case 'PUSH': {
                let settings: IModalSettings | null = null;
                const jwt = store.getState().user?.jwt?.token;

                if (location.pathname.startsWith('/user-login')) {

                    if (!jwt) {
                        settings = {
                            windowType: 'SMALL',
                            alignType: 'CENTER',
                            content: {
                                component: LoginModal,
                                props: {}
                            },
                        };
                    }
                }

                if (location.pathname.startsWith('/product/')) {
                    const slugs = location.pathname.replace('/product/', '').split('/');

                    // const stored = storedEntity([slugs[0]], slugs[1]);
                    const stored: any = null;
                    const promise = stored ? Promise.resolve(stored as IProduct) : ProductService.getByName(slugs[0], slugs[1]);
                    settings = pdpModalPromiseSettings(promise, null, null, query.get('video') ? true : false, true);
                } else if (location.pathname.startsWith('/bundle/age/')) {
                    const name = location.pathname.replace('/bundle/age/', '').trim();
                    // const stored = storedEntity(['ageBundles'], name);
                    const stored: any = null;
                    const promise = stored ? Promise.resolve(stored as IProduct) : ProductService.getByName('bundle-age', name);
                    settings = pdpAgeBundleModalSettings(promise);
                } else if (location.pathname.startsWith('/bundle/variety/')) {
                    const name = location.pathname.replace('/bundle/variety/', '').trim();
                    // const stored = storedEntity(['varietyPacks'], name);
                    const stored: any = null;
                    const promise = stored ? Promise.resolve(stored as IProduct) : ProductService.getByName('variety-pack', name);
                    settings = pdpVarietyPackModalSettings(promise);
                } else if (location.pathname.startsWith('/survey')) {
                    settings = surveyModalSettings();
                } else if (location.pathname.startsWith('/box-builder')) {
                    settings = builderModalSettings(false, true);
                } else if (location.pathname.startsWith('/shop')) {
                    dispatch(closeShopModals());
                    page('ShopPage');
                } else if (location.pathname.startsWith('/cart')) {
                    dispatch(closeShopModals());
                }

                if (settings) {
                    settings.path = location.pathname;
                    settings.onCloseKeepOverflowHidden = true;
                    if(!(location.pathname.startsWith('/survey') && initialAction === 'POP')) {
                        dispatch(pushShopModal(settings));
                    }
                }

                // Private routing starts here
                if (!jwt) {
                    break;
                }

                if (location.pathname.startsWith('/edit/') ||
                    location.pathname.startsWith('/swap/') ||
                    location.pathname.startsWith('/buy-again/')
                ) {
                    settings = {
                        windowType: 'SHOP',
                        alignType: 'CENTER',
                        heightType: 'FULL',
                        onClose: () => {},
                        scroll: false,
                        content: {
                            component: null,
                            props: {}
                        },
                    };
                }

                // Edit Flow
                if (location.pathname.startsWith('/edit/personalized')) {
                    settings.content.component = EditPersonalized;
                } else  if (location.pathname.startsWith('/edit/milestones')) {
                    settings.content.component = EditMilestones;
                } else if (location.pathname.startsWith('/edit/box')) {
                    settings = builderModalSettings(true, null, true);
                }

                // Swap Flow
                if (location.pathname.startsWith('/swap/initial')) {
                    settings.content.component = SwapInitialModal;
                } else if (location.pathname.startsWith('/swap/products')) {
                    settings.content.component = SwapProductsModal;
                } else if (location.pathname.startsWith('/swap/packs')) {
                    settings.content.component = SwapPacksModal;
                } else if (location.pathname.startsWith('/swap/survey')) {
                    settings.content.component = SwapSurveyModal;
                } else if (location.pathname.startsWith('/swap/builder')) {
                    settings.content.component = SwapBuilderModal;
                } else if (location.pathname.startsWith('/swap/confirm')) {
                    settings.content.component = SwapConfirmationModal;
                } else if (location.pathname.startsWith('/swap/oos') && query.get('boxId') && query.get('productId')) {
                    settings.content.component = SwapOOSMOdal;
                    settings.content.props = {
                        boxId: Number(query.get('boxId')),
                        productId: Number(query.get('productId')),
                    };
                }

                // Buy Again Flow
                if (location.pathname.startsWith('/buy-again/') && location.pathname.indexOf('/list') > 0) {
                    settings.content.component = BuyAgainList;
                } else if (location.pathname.startsWith('/buy-again/personalized')) {
                    settings.content.component = BuyAgainPersonalized;
                } else {
                    dispatch(closeShopModals());
                }

                if (location.pathname.startsWith('/subscription')) {
                    dispatch(closeShopModals());
                }

                if (settings?.content?.component) {
                    settings.path = location.pathname;
                    settings.onCloseKeepOverflowHidden = true;

                    // this is not great in terms of what it does to the store, consider imprving
                    dispatch(updateShopModal(settings));
                }

                break;
            }
        }

        (window as any).shopLocations.push(location.key);
    }

    useEffect(() => {
        if (!(window as any).shopLocations) {
            (window as any).shopLocations = [];
        }
        navigate(location, 'PUSH');

        //@ts-ignore
        return history.listen((location, action) => navigate(location, action))
    }, [history]);

    useEffect(() => {
        const trackShareASale = () => {
            (document.getElementById('shareasale') || { remove: () => { } }).remove()
            let script = document.createElement('script');

            script.setAttribute('id', 'shareasale');
            script.setAttribute('src', 'https://www.dwin1.com/19038.js');
            script.setAttribute('type', 'text/javascript');
            document.getElementsByTagName('head')[0].appendChild(script);
        }

        trackShareASale();
        gtmDispatch({ event: 'PageView' });

        const unlisten = history.listen(() => {
            trackShareASale();
            gtmDispatch({ event: 'PageView' });
        });

        return () => {
            unlisten();
        }
    }, []);

    const shouldHideSection = window.location.pathname.includes('/landing/nourishment-for-every-stage-of-growth');

    return (
        <RouterProvider>
            <div id="content-section" className={`e-page-content-wrap`} js-content-wrap="" style={shouldHideSection ? { paddingTop: 0 } : {}}>
                <Route exact path="/" render={() =>
                    <HomePage />
                } />
                <Suspense fallback={<PageLoader overlay={true}/>}>
                    <Switch>
                        {/* REDIRECTS */}
                        <Route exact path="/orders" render={() => <Redirect to={{ pathname: '/order-history' }} />} />
                        <Route exact path="/account" render={() => <Redirect to={{ pathname: '/for-you' }} />} />

                        {/* VISITOR PAGES */}
                        <Route exact path="/" />
                        <Route exact path="/about-cerebelly" component={OurStoryPage} />
                        <Route exact path="/privacy" component={PrivacyPolicy} />
                        <Route exact path="/messaging-privacy" component={MessagingPrivacyPolicy} />
                        <Route exact path="/referral-terms" component={ReferralTerms} />
                        <Route exact path="/terms" component={TermsUse} />
                        <Route exact path="/terms-sale" component={TermsSale} />
                        <Route exact path="/accessibility-statement" component={AccessibilityStatement} />
                        <Route exact path="/science" component={Science} />
                        <Route exact path="/nutrient-glossary/what-is-*" component={NutrientDetailsPage} />
                        <Route exact path="/baby-food-delivered" component={HowItWorks} />
                        <Route exact path="/baby2baby/" component={Baby2Baby} />
                        <Route exact path="/recycling/" component={Recycling} />
                        <Route exact path="/sample-kit/" component={SampleKit} />
                        <Route exact path="/reviews/" component={BuilderIOReviews} />
                        <Route exact path="/landing/testimonials" component={SocialProof} />
                        <Route exact path="/landing/why-cerebelly" component={Listicle} />
                        <Route exact path="/where-to-buy/" component={WhereToBuyPage} />
                        <Route exact path="/reset-password" component={ResetPasswordPage} />
                        <Route exact path="/landing/*" component={BuilderIOLanding} />
                        <Route exact path="/gift" component={GiftPage} />
                        <Route exact path="/gift-review" component={GiftPage} />
                        <Route exact path="/order-gift" component={GiftOrderConfirmationPage} />

                        <Route exact path="/logout" component={Logout} />
                        <Route exact path="/user-login" render={() => <HomePage/>} />

                        {/* BLOGPOSTS */}
                        <Route exact path="/parenting-is-an-art" component={PIAAPage} />
                        <Route exact path="/parenting-is-an-art/*" component={PIAAPage} />

                        <Route exact path="/product/:category/:name" component={ShopPage} />
                        <Route exact path="/bundle/age/:name" component={ShopPage} />
                        <Route exact path="/bundle/variety/:name" component={ShopPage} />
                        <Route exact path="/survey" component={ShopPage} />
                        <Route exact path="/box-builder" component={ShopPage} />

                        {/* CREATE A BOX PAGES */}
                        <Route exact path="/shop" component={ShopPage} />
                        <Route exact path="/shop/*" component={ShopPage} />
                        <Route exact path="/products" render={() => <Redirect to={{ pathname: '/shop' }} />} />
                        <Route exact path="/cart" component={CartPage} />

                        {/* LOGGED IN PAGES */}
                        <PrivateRoute exact path="/shop/:id" component={ShopPage} />
                        <PrivateRoute exact path="/order-confirmation" component={OrderConfirmationPage} />
                        <PrivateRoute exact path="/for-you" component={DashboardLayoutPage} />
                        <PrivateRoute exact path="/my-board" component={DashboardLayoutPage} />
                        <PrivateRoute exact path="/board-menu" component={DashboardLayoutPage} />
                        <PrivateRoute exact path="/subscription" component={DashboardLayoutPage} />
                        <PrivateRoute exact path="/subscription/:id" component={DashboardLayoutPage} />
                        <PrivateRoute exact path="/child/*" component={ChildPage} />
                        <PrivateRoute exact path="/orders" component={DashboardLayoutPage} />
                        <PrivateRoute exact path="/order-history" component={DashboardLayoutPage} />
                        <PrivateRoute exact path="/order/:id" component={DashboardLayoutPage} />
                        <PrivateRoute exact path="/shipping-billing" component={DashboardLayoutPage} />
                        <PrivateRoute exact path="/account-settings" component={DashboardLayoutPage} />
                        <PrivateRoute exact path="/cancellation" component={CancellationPage} />
                        <PrivateRoute exact path="/referral" component={DashboardLayoutPage} />
                        <Route exact path="/rewards" render={() => {
                            const jwt = store.getState().user?.jwt?.token;

                            return !jwt
                                ? <LoyaltyProgramPage />
                                : <DashboardLayoutPage location={location} />
                        }} />

                        {/* LOGGED IN MODAL ROUTES */}
                        <PrivateRoute exact path="/swap/*" component={DashboardLayoutPage} />
                        <PrivateRoute exact path="/buy-again/*" component={DashboardLayoutPage} />
                        <PrivateRoute exact path="/edit/*" component={DashboardLayoutPage} />

                        <Route exact path="/reactivate-subscriptions"
                            render={() => <Redirect to={{ pathname: '/account-settings' }} />} />

                        <Route exact path="/checkout" render={() => {
                            const jwt = store.getState().user?.jwt?.token;

                            return !jwt ?
                                <GuestCheckoutPage /> :
                                <LoggedCheckoutPage />
                        }} />

                        {/*this.state.reload_get_param_route_location &&
                        <Route exact path="/reload" component={null} />
                        */}

                        {/* 404 */}
                        <Route exact path="/404" component={NotFoundPage}/>
                        <Route path="*" component={NotFoundPage}/>
                    </Switch>
                </Suspense>
            </div>
            {
                shopModals.map((modal, index) =>
                    <Modal key={index} settings={modal} props={{ hide: hideShopModal }} onClose={hideShopModal} />
                )
            }
            {pathname !== '/' || location.search !== '' ?
                <YotpoInit /> : null}

            {/* Story 4994: uncomment to use Juice Pixes */}
            {/* <JuicePixes /> */}

            <NextDoorPixes />
            <StackAdaptPixes />
            <CRSOptimizer />
        </RouterProvider>
    );
}

// @ts-ignore
export default withRouter(Routes);